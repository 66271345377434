const { uniqueId } = require("lodash")

const POPULAIRES = [
    {
        title: "Mains - Pose de vernis",
        time: "10 min - 15 min",
        price: "à partir de 15 €",
        children: [
            {
                title: "Vernis coloré",
                time: "10 min",
                price: "15 €",
            },
            {
                title: "Vernis French",
                time: "15 min",
                price: "20 €",
            }
        ]
    },
    {
        title: "Manucure et pose de vernis semi-permanent",
        time: "40 min",
        price: "à partir de 39 €",
        children: [
            {
                title: "Vernis semi-permanent coloré",
                time: "40 min",
                price: "39 €",
            },
            {
                title: "Vernis semi-permanent French",
                time: "40 min",
                price: "44 €",
            }
        ]
    },
]

const MANUCURES = [
    {
        title: "Mains - Pose de vernis",
        time: "15 min - 20 min",
        price: "à partir de 15 €",
        children: [
            {
                title: "Vernis coloré",
                time: "15 min",
                price: "15 €",
            },
            {
                title: "Vernis French",
                time: "20 min",
                price: "20 €",
            }
        ]
    },
    {
        title: "Mains - Pose de vernis semi-permanent",
        time: "25 min",
        price: "à partir de 29 €",
        children: [
            {
                title: "Vernis couleur",
                time: "25 min",
                price: "29 €",
            },
            {
                title: "Vernis semi-permanent French",
                time: "25 min",
                price: "34 €",
            }
        ]
    },
    {
        title: "Manucure et pose de vernis",
        time: "35 min",
        price: "à partir de 21 €",
        children: [
            {
                title: "Vernis coloré",
                time: "35 min",
                price: "21 €",
            },
            {
                title: "Vernis French",
                time: "35 min",
                price: "26 €",
            }
        ]
    },
    {
        title: "Manucure et pose de vernis semi-permanent",
        time: "40 min",
        price: "à partir de 39 €",
        children: [
            {
                title: "Vernis semi-permanent coloré",
                time: "40 min",
                price: "39 €",
            },
            {
                title: "Vernis semi-permanent French",
                time: "40 min",
                price: "44 €",
            }
        ]
    },
    {
        title: "Homme - Manucure",
        time: "30 min",
        price: "20 €"
    },
    {
        title: "Mains - Dépose de vernis semi-permanent",
        time: "15 min",
        price: "7 €"
    },
    {
        title: "Mains - Baby Boomer, manucure et pose de vernis semi-permanent",
        time: "45 min",
        price: "50 €"
    },
    {
        title: "Limage",
        time: "15 min",
        price: "10 €"
    },
]

const BEAUTE_DES_PIEDS = [
    {
        title: 'Pieds - Pose de vernis',
        time: '15 min - 20 min',
        price: 'à partir de 15 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '15 min',
                price: '15 €',
            },
            {
                title: 'Vernis French',
                time: '20 min',
                price: '20 €',
            }
        ]
    },
    {
        title: 'Pieds - Pose de vernis semi-permanent',
        time: '20 min - 25 min',
        price: 'à partir de 29 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '20 min',
                price: '29 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '25 min',
                price: '34 €',
            },
        ]
    },
    {
        title: 'Beauté des pieds et pose de vernis',
        time: '40 min',
        price: 'à partir de 36 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '40 min',
                price: '36 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '40 min',
                price: '41 €',
            },
        ]
    },
    {
        title: 'Beauté des pieds et pose de vernis semi-permanent',
        time: '45 min',
        price: 'à partir de 50 €',
        children: [
            {
                title: 'Vernis semi-permanent coloré',
                time: '45 min',
                price: '50 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '45 min',
                price: '55 €',
            }
        ]
    },
    {
        title: 'Pieds - Dépose de vernis semi-permanent',
        time: '10 min',
        price: '7 €'
    },
    {
        title: 'Homme - Beauté des pieds',
        time: '30 min',
        price: '35 €'
    },
    {
        title: 'Limage des ongles des pieds',
        time: '15 min',
        price: '10 €'
    },
]

const MANUCURES_PEDICURES = [
    {
        title: 'Homme - Manucure et beauté des pieds',
        time: '1 h',
        price: '47 €'
    },
    {
        title: 'Manucure, beauté des pieds et pose de vernis',
        time: '1 h 10 min - 1 h 20 min',
        price: 'à partir de 50 €',
        children: [
            {
                title: 'Vernis coloré',
                time: '1 h 10 min',
                price: '50 €',
            },
            {
                title: 'Vernis French',
                time: '1 h 20 min',
                price: '60 €',
            }
        ]
    },
    {
        title: 'Femme - Manucure et beauté des pieds',
        time: '1 h 10 min',
        price: '50 €'
    },
    {
        title: 'Manucure, beauté des pieds et pose de vernis semi-permanent',
        time: '1 h 20 min - 1 h 25 min',
        price: 'à partir de 83 €',
        children: [
            {
                title: 'Vernis semi-permanent coloré',
                time: '1 h 20 min',
                price: '83 €',
            },
            {
                title: 'Vernis semi-permanent French',
                time: '1 h 25 min',
                price: '93 €',
            }
        ]
    },
    {
        title: 'Femme - Suppléments Perfect Spa',
        time: '5 min',
        price: 'à partir de 5 €',
        children: [
            {
                title: 'Serviette chaude - Mains et pieds',
                time: '5 min',
                price: '5 €',
            },
            {
                title: 'Gommage - Mains et pieds',
                time: '5 min',
                price: '5 €',
            },
            {
                title: 'Serviette chaude - Mains ou pieds',
                time: '5 min',
                price: '5 €',
            },
            {
                title: 'Gommage - Mains ou pieds',
                time: '5 min',
                price: '15 €',
            },
        ]
    },
]

const FAUX_ONGELS = [
    {
        title: "Dépose de faux ongles",
        time: "20 min",
        price: "17 €"
    },
    {
        title: "Gel - Pose d'ongles et pose de vernis semi-permanent",
        time: "1 h - 1 h 10 min",
        price: "A partir de 68 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "68 €"
            },
            {
                title: "Vernis French",
                time: "1 h 10 min",
                price: "73 €"
            }
        ]
    },
    {
        title: "Gel - Remplissage d'ongles et pose de vernis semi-permanent",
        time: "1 h 5 min - 1 h 10 min",
        price: "A partir de 58 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h 5 min",
                price: "58 €"
            },
            {
                title: "Vernis French",
                time: "1 h 10 min",
                price: "63 €"
            }
        ]
    },
    {
        title: "Réparation d'un ongle cassé",
        time: "10 min",
        price: "7 €"
    },
    {
        title: "Résine - Pose d'ongles et pose de vernis semi-permanent",
        time: "1 h 5 min - 1 h 10 min",
        price: "A partir de 58 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h 5 min",
                price: "58 €"
            },
            {
                title: "Vernis French",
                time: "1 h 10 min",
                price: "63 €"
            }
        ]
    },
    {
        title: "Résine - Remplissage d'ongles et pose de vernis semi-permanent",
        time: "1 h - 1 h 10 min",
        price: "A partir de 48 €",
        children: [
            {
                title: "Vernis coloré",
                time: "1 h",
                price: "48 €"
            },
            {
                title: "Vernis French",
                time: "1 h 10 min",
                price: "53 €"
            }
        ]
    },
    {
        title: "Résine - Remplissage d'ongles Ombré Dégradé 2 couleurs",
        time: "1 h 5 min",
        price: "58 €"
    },
    {
        title: "Nail art",
        time: "5 min",
        price: "A partir de 0,5 €",
        children: [
            {
                title: "Décoration avec diamant - À l'unité",
                time: "5 min",
                price: "0,5 €"
            },
            {
                title: "Décoration avec autocollant - 1 doigt",
                time: "5 min",
                price: "2 €"
            },
            {
                title: "Autre décoration (selon votre modèle) - 1 doigt",
                time: "10 min",
                price: "3 €"
            },
            {
                title: "Supplément couleur (à partir de)",
                time: "5 min",
                price: "À partir de 5 €"
            },
            {
                title: "Effet chrome miroir en vernis semi-permanent",
                time: "5 min",
                price: "10 €"
            },
        ]
    },
    {
        title: "Gel - Ponçage d'ongles et pose de vernis semi-permanent",
        time: "35 min",
        price: "40 €"
    },
    {
        title: "Résine - Ponçage d'ongles et pose de vernis semi-permanent",
        time: "40 min",
        price: "40 €"
    },
    {
        title: "Gainage des ongles",
        time: "45 min",
        price: "58 €"
    },
    {
        title: "Gel X - Pose d'ongles",
        time: "1 h - 1 h 10 min",
        price: "A partir de 58 €",
        children: [
            {
                title: "Gel coloré",
                time: "1 h",
                price: "58 €"
            },
            {
                title: "Gel French",
                time: "1 h 10 min",
                price: "63 €"
            }
        ]
    },
    {
        title: "Gel X - Dépose d'ongles",
        time: "25 min",
        price: "17 €"
    },
]

const flatten = (arr, results) => {
    arr.forEach(s => {
        if(!s.children) {
            let obj = Object.assign({}, s);
            obj.description = s.title + ' | ' + s.price;
            obj.name = s.title;
            obj.id = uniqueId();
            results.push(obj);
        }
        else {
            s.children.forEach(y => {
                let obj = Object.assign({}, y);
                obj.description = s.title + ' | ' + y.title + ' | ' + y.price;
                obj.name = s.title + ' | ' + y.title;
                obj.id = uniqueId();
                results.push(obj);
            });
        }
    })
}

const ALL = (() => {
    let results = [];
    flatten(MANUCURES, results);
    flatten(BEAUTE_DES_PIEDS, results);
    flatten(MANUCURES_PEDICURES, results);
    flatten(FAUX_ONGELS, results);
    return results.sort((s1, s2) => s1.description > s2.description ? 1 : -1);
})();

module.exports = {
    prestations: [
        { title: 'Manucure', data: MANUCURES },
        { title: 'Beauté Des Pieds', data: BEAUTE_DES_PIEDS },
        { title: 'Manucure Et Beauté Des Pieds', data: MANUCURES_PEDICURES },
        { title: 'Pose De Faux Ongles', data: FAUX_ONGELS },
    ],
    populaires: POPULAIRES,
    all_prestations: ALL
}