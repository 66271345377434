import { Link, navigate } from "gatsby";
import {
  Box,
  Button,
  Heading,
  Image,
  Layer,
  ResponsiveContext,
  Text
} from "grommet";
import { Clock, Location } from "grommet-icons";

import React from "react";
import { Carousel } from 'react-carousel-minimal';
import About from "../components/elements/about";
import Layout from "../components/elements/layout";
import Menu from "../components/elements/leftmenu";
import Marques from "../components/elements/marques";
import Populaires from "../components/elements/prestation/populaires";
import Review, { LoadReview } from "../components/elements/review";
import MainSeparator from "../components/elements/separator/main_separator";
import Team from "../components/elements/team";
import Works from "../components/elements/works";
import Header from '../components/header';
import MainMenu from '../components/menu';
import salon from '../config/data.json';
import cache_posts from '../config/posts.json';
import mot from '../images/home/1.jpeg';
import hai from '../images/home/2.jpeg';
import ba from '../images/home/3.jpeg';
import bon from '../images/home/4.jpeg';
import nam from '../images/home/5.jpeg';
import sau from '../images/home/6.jpeg';
import bay from '../images/home/7.jpeg';
import instagram from "../images/instagram.png";
import service, { getPosts, getSalon, tracking } from '../services/service';

const data = [
  { image: mot, caption: "" },
  { image: hai, caption: "" },
  { image: ba, caption: "" },
  { image: bon, caption: "" },
  { image: nam, caption: "" },
  { image: sau, caption: "" },
  { image: bay, caption: "" },
];
const captionStyle = {
  fontSize: '2em',
  fontWeight: 'bold',
}
const slideNumberStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
}

const Margin = () => <Box height="xxsmall" />
class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      salon: salon,
      posts: cache_posts,
      showNotif: false,
    }
  }

  componentDidMount = () => {
    getSalon()
      .then(res1 => {
        if (typeof window !== undefined && !res1.shouldNotCache) {
          let obj = {
            salon: {
              data: res1.data
            },
            updatedAt: new Date().getTime()
          }
          localStorage.setItem('salon', JSON.stringify(obj));
        }
        this.setState({ salon: res1.data }, () => {
          getPosts().then(res => {
            this.setState({ posts: res.data })
            if (typeof window !== undefined && !res.shouldNotCache) {
              let obj = {
                posts: {
                  data: res.data
                },
                updatedAt: new Date().getTime()
              }
              localStorage.setItem('insta_posts', JSON.stringify(obj));
            }
          });
        })
      })

    if (typeof window !== undefined) {
      let source = new URLSearchParams(window.location.search).get('s');
      if (!source) {
        source = new URLSearchParams(window.location.search).get('source');
      }
      const fb = new URLSearchParams(window.location.search).get('fbclid');
      if (fb && !source) {
        source = 'facebook';
        const url = new URL(window.location.href);
        url.searchParams.set('s', 'facebook');
        window.history.replaceState(null, null, url);
      }

      let userId = localStorage.getItem('userId');
      if (!userId) {
        userId = Math.random().toString(36).substring(3);
        localStorage.setItem('userId', userId);
      }

      if (source) {
        tracking({ source, userId, type: 'Visiting with source' });;
      }

    }

  }

  render() {
    return (
      <Layout style={{ justifyContent: "center", alignContent: "center" }}>
        <Box width="xlarge" justify="center" align="center" alignSelf="center">
          <ResponsiveContext.Consumer>
            {size =>
              <Box width="xlarge" pad="medium">
                {this.state.showNotif &&
                  <Layer full={size === 'small' ? true : false}>
                    <Box width={size === 'small' ? 'full' : 'medium'} gap='medium' justify="center" align="center">
                      <Box pad={'medium'} gap='small'>
                        <Box pad={'small'}>
                          <Heading level={2} textAlign="center">
                            🚨 Attention : Nos prix augmentent bientôt ! 🚨
                          </Heading>

                          <Text>
                            Les tarifs actuels seront disponibles jusqu’au <strong>31/12/2024</strong> seulement. 💥
                          </Text>
                          <Heading level={4}>
                            👉 Pourquoi maintenant ?
                          </Heading>
                          <Text>
                            Parce que nous continuons d’ajouter plus de valeur, et ça vaut chaque centime. <br/>Mais voici la bonne nouvelle : vous pouvez encore profiter des anciens prix si vous agissez rapidement.
                          </Text>

                          <Heading level={5} style={{ fontWeight: 'bold' }}>
                            🗓️ Agissez avant 31/12 pour sécuriser votre tarif préférentiel.💪
                          </Heading>

                          <Heading level={4} textAlign="center" >
                          Ne manquez pas cette opportunité – après, ce sera trop tard !🚀🚀🚀
                          </Heading>
                        </Box>
                        <Box direction="row" gap="small" justify="center">

                        <Button width='medium' primary label='Prendre rdv ' onClick={() =>{
                          service.tracking({ source: 'augument prix', type: 'Click on rdv button' });
                          navigate('https://widget.treatwell.fr/salon/418312/menu/');
                        }} />
                        <Button width='medium' secondary label='Fermer' onClick={() => this.setState({ showNotif: false })} />
                        </Box>
                      </Box>
                    </Box>
                  </Layer>
                }
                <Header data={this.state.salon.data} size={size} />
                <MainMenu />
                <Box style={{
                  padding: "0 20px",
                  width: '100%'
                }}>
                  <Carousel
                    data={data}
                    time={5000}
                    width={'100%'}
                    captionStyle={captionStyle}
                    radius="10px"
                    slideNumber={true}
                    slideNumberStyle={slideNumberStyle}
                    captionPosition="bottom"
                    automatic={true}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={size === 'small' ? false : true}
                    thumbnailWidth={size === 'small' ? "40px" : '100px'}
                    style={{
                      textAlign: "center",
                      maxWidth: "2000px",
                      maxHeight: "2000px",
                      margin: "40px auto",
                    }}
                  />
                </Box>
                <Box gap="small">
                  <Box direction="row" gap="small">
                    <Location color='#3b3b3b' fontWeight='bold' />
                    <Text style={{ color: '#3b3b3b', fontWeight: 'bold' }}>15 Avenue du Général de Gaulle, Saint-Mandé, 94160</Text>
                  </Box>
                  <Box direction="row" gap="small">
                    <Clock color='#3b3b3b' fontWeight='bolder' />
                    <Text style={{ color: '#3b3b3b', fontWeight: 'bold' }}>Ouvert Aujourd'hui: 10:00 - 19:30</Text>
                  </Box>
                </Box>
                <MainSeparator />

                <Box align="center" gap="medium" direction="row">
                  <Heading level="3">Notre travail</Heading>
                  <Box style={{ boxShadow: 'none' }}>
                    <Link target={'_blank'} to="https://www.instagram.com/beperfectmanucure">
                      <Image src={instagram} width={50}
                      />
                    </Link>
                  </Box>
                </Box>
                <Works posts={this.state.posts.slice(0, 15)} size={size} />
                <Margin />

                <Box gap="small" direction={size === 'small' ? "column" : "row"}>
                  <Box direction="row" style={{ width: size === 'small' ? '100%' : '30%' }}>
                    <Heading level="3">Les plus populaires</Heading>
                  </Box>
                  <Box direction="row" style={{ width: size === 'small' ? '100%' : '70%' }}>
                    <Populaires size={size} />
                  </Box>
                </Box>
                <Margin />
                <Heading level="3">Recherchez dans notre liste de prestations</Heading>
                <Box gap="small">
                  <Menu size={size} />
                </Box>
                <Margin />
                <Heading level="3">Avis des clients</Heading>
                <Review data={this.state.salon.data} size={size} />
                <MainSeparator />
                <LoadReview reviews={this.state.salon.reviews.reviews} size={size} />
                <Margin />
                <Heading level="3">Rencontrez l'équipe</Heading>
                <Team employees={this.state.salon.data?.employees} size={size} />
                <Margin />
                <Heading level="3">A propose</Heading>
                <About size={size} />
                <Margin />
                <Heading level="3">Nos Marques</Heading>
                <Marques />
              </Box>
            }
          </ResponsiveContext.Consumer>

          <Box style={{ height: 200 }} />
        </Box>
      </Layout>
    )
  }
}

export default IndexPage;
